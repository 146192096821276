import { useQuery } from '@tanstack/react-query';
import {
  getCustomerActivity,
  getCustomerActivityDownloads,
} from 'pages/admin/customer-activity/customer-activity.thunks';

export const useCustomerActivity = (currentPage: number, queryKey: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getCustomerActivity({ page: currentPage }),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data,
  };
};

export const useCustomerActivityDownloads = (queryKey: string, id: string, page: number) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getCustomerActivityDownloads({ seller: id, page }),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data,
  };
};
