import { getSilentFetch, postSilentFetch, putFetch } from 'request/request';
import { store } from 'redux/store';
import { setCustomerActivity } from 'pages/admin/customer-activity/customer-activity.slice';
import {
  CustomerActivityInterface,
  ResponseCustomerActivityDownloadsInterface,
} from 'interfaces/customer-activity.interface';

export const getCustomerActivity = async (values): Promise<CustomerActivityInterface[]> => {
  const res = await postSilentFetch('/customer-activity/admin/seller/page', values);
  if (res) {
    store.dispatch(setCustomerActivity(res));
  }
  return res;
};

export const getCustomerActivityDownloads = async (values): Promise<ResponseCustomerActivityDownloadsInterface> => {
  const res = await postSilentFetch('/customer-activity/admin/downloads/page', values);

  return res;
};

export const addCustomerActivity = async (values): Promise<CustomerActivityInterface> => {
  const res = await postSilentFetch('/customer-activity/admin/seller', values);
  if (res) {
    store.dispatch(setCustomerActivity(res));
  }
  return res;
};

export const getCustomerActivityDetails = async (id): Promise<CustomerActivityInterface> => {
  const res = await getSilentFetch(`/customer-activity/admin/seller/${id}`);
  return res;
};

export const updateCustomerActivity = async (id, values): Promise<CustomerActivityInterface> => {
  const res = await putFetch(
    `/customer-activity/admin/seller/${id}`,
    { ...values, _id: id },
    'Customer Activity Updated Successfully!'
  );
  return res;
};
