import { ShimmerLabel, ShimmerRow, ShimmerTxtField, ShimmerWrap } from 'components/shimmer/utils';

const CustomerActivityShimmer = () => (
  <div className="w-100">
    <ShimmerWrap>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
      <ShimmerRow>
        <ShimmerLabel />
        <ShimmerTxtField />
      </ShimmerRow>
    </ShimmerWrap>
  </div>
);

export default CustomerActivityShimmer;
