import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import React from 'react';
import { CustomerActivityInterface } from 'interfaces/customer-activity.interface';
import { friendlyDate } from '../../../lib/formatting.helpers';

function CustomerActivityTableData(): TableColumnRowInterface[] {
  const columnData = [
    {
      key: 'name',
      render: (rowData: CustomerActivityInterface) => {
        const id = rowData?._id;
        return (
          <div className="flex flex-row">
            <a href={`/dashboard/customer-activity/${id}`} className="hover:underline">
              {rowData?.name}
            </a>
          </div>
        );
      },
    },
    {
      key: 'sellerId',
      render: (rowData: CustomerActivityInterface) => {
        return <div className="flex flex-row">{rowData?.sellerId || '--'}</div>;
      },
    },

    {
      key: 'status',
      render: (rowData: CustomerActivityInterface) => {
        return <div className="flex flex-row">{rowData?.status}</div>;
      },
    },
    {
      key: 'createdAt',
      render: (rowData: CustomerActivityInterface) => {
        return <div className="flex flex-row">{friendlyDate(new Date(rowData.createdAt))}</div>;
      },
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default CustomerActivityTableData;
