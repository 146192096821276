import React from 'react';
import { MultiSelect } from '@mantine/core';
import { Field } from 'react-final-form';
import FormLabel from 'components/forms/form-label';
import { useSearchUser } from './hooks/useSearchUser';

function CustomerActivitySearchUser() {
  const { data = [] } = useSearchUser();
  const options = data.map((user) => ({ label: user.email, value: user._id }));

  return (
    <div className={`sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center`}>
      <FormLabel label="Users" />
      <div className={`mt-1 sm:mt-0 sm:col-span-3`}>
        <Field
          name="user"
          render={({ input, meta }) => {
            return (
              <>
                <MultiSelect
                  {...input}
                  defaultValue={input.value || []}
                  data={options}
                  searchable
                  onChange={(value) => {
                    input.onChange(value);
                  }}
                  error={meta.touched && meta.error}
                  nothingFound="Nothing found..."
                  limit={20}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

export default CustomerActivitySearchUser;
