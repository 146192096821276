import { useQuery } from '@tanstack/react-query';
import { postSilentFetch } from 'request/request';

export const useSearchUser = () => {
  const queryKey = ['all-user'];
  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: async () => await postSilentFetch('/users/superadmin/get-user-all', {}),
  });
  return {
    data,
    isLoading,
    queryKey,
  };
};
