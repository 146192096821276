import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React, { useState } from 'react';
import { useCustomerActivity } from 'pages/admin/customer-activity/customer-activity.hooks';
import CustomerActivityTableData from 'pages/admin/customer-activity/customer-activity.tabledata';
import { useAppSelector } from 'redux/hooks';
import { selectCustomerActivity } from 'pages/admin/customer-activity/customer-activity.slice';
import TablePagination from '../../../components/tables/table.pagination';

const headers = ['name', 'Seller Id', 'status', 'Created Date', 'Action'];

const CustomerActivity = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { isFetching } = useCustomerActivity(currentPage, 'customer-activity' + currentPage);
  const columns = CustomerActivityTableData();
  const colSpan = headers.length;
  const { docs, hasNextPage, hasPrevPage, nextPage, page, pagingCounter, prevPage, totalPages } =
    useAppSelector(selectCustomerActivity);
  return (
    <>
      <SubpageContainer title="Customer Activity" showAdd addUrl="new" showAddString="Add Seller">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={docs}
            noDataMsg="No Sellers"
            buttonLabel="New Seller"
          >
            <TableBody columns={columns} data={docs} deleteUrl="/customer-activity/admin/seller/" confirmField="name" />
          </RequestContainer>
        </Table>
        <TablePagination
          setCurrentPage={setCurrentPage}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextPage={nextPage}
          page={page}
          pagingCounter={pagingCounter}
          prevPage={prevPage}
          totalPages={totalPages}
          docs={docs?.length}
        />
      </SubpageContainer>
    </>
  );
};

export default CustomerActivity;
