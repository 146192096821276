import React, { useState } from 'react';
import { useCustomerActivityDownloads } from 'pages/admin/customer-activity/customer-activity.hooks';
import { useParams } from 'react-router-dom';
import { ResponseCustomerActivityDownloadsInterface } from 'interfaces/customer-activity.interface';
import IfElse from 'components/conditionals/if-else.conditional';
import { Badge } from '@mantine/core';
import { friendlyDateTime } from 'lib/formatting.helpers';
import CustomerActivityShimmer from 'pages/admin/customer-activity/customer-activity.shimmer';
import ShimmerWrapper from 'components/shimmer';

const CustomerActivityDownloads = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { id = '' } = useParams();
  const { isFetching, data } = useCustomerActivityDownloads('customer-activity', id, currentPage);
  const customerActivityData = data as ResponseCustomerActivityDownloadsInterface;
  const sortedData = customerActivityData?.docs?.sort(
    (a, b) => new Date(b.downloadDate).getTime() - new Date(a.downloadDate).getTime()
  );

  return (
    <div className="space-y-6 bg-white rounded-md mt-6 py-6 ">
      <div className={`flex mx-auto px-4 sm:px-6  lg:px-8 mb-3`}>
        <h1 className="text-2xl flex flex-col justify-end font-semibold text-gray-900">Download History</h1>
      </div>
      <div className="max-h-64 overflow-auto">
        <ShimmerWrapper loading={isFetching || !customerActivityData} fallback={<CustomerActivityShimmer />}>
          {Array.isArray(sortedData) &&
            sortedData.map((data, index) => {
              return (
                <div className="flex flex-row px-6 py-3 items-center" key={index}>
                  <IfElse show={!data.isError}>
                    <Badge color="green" className="mr-4">
                      Success
                    </Badge>
                    <Badge color="red" className="mr-4">
                      Error
                    </Badge>
                  </IfElse>
                  {friendlyDateTime(new Date(data.downloadDate))}
                </div>
              );
            })}
        </ShimmerWrapper>
      </div>
    </div>
  );
};

export default CustomerActivityDownloads;
